import { render, staticRenderFns } from "./community.vue?vue&type=template&id=71551093&scoped=true&"
import script from "./community.vue?vue&type=script&lang=ts&"
export * from "./community.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71551093",
  null
  
)

export default component.exports