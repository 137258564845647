






































































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiCommunityDel,
    apiCommunityStatus, // 修改小区状态
    apiCommunityList // 小区列表
} from '@/api/delivery'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    pager = new RequestPaging()
    apiCommunityList = apiCommunityList
    // 获取列表
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiCommunityList,
            params: {}
        })
    }
    // 是否启用
    handleStatus(value: number, row: object) {
        let params: any = Object.create(null)
        params = {...row}
        params.status = value
        apiCommunityStatus(params).then(() => {
            this.getList()
        }).catch(e => {
            this.getList()
        })
    }
    // 删除
    handleDelete(id: number) {
        apiCommunityDel({ id }).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
}
